
  import {defineComponent, inject, onMounted, ref, watch} from "vue";
import { useRoute } from "vue-router";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from 'qs'
  import Modal from "@/components/UI/Modal";
  import router from "@/router";
export default defineComponent({
  name: "Footer",
  setup() {
    const route = useRoute();
    const shopCarNum = ref()
    const userInfo = inject("userInfo") as any;

    //查看购物车
    function toViewCart() {
      if(!userInfo || !userInfo.id){
        Modal({
          title: "温馨提示",
          content: '<div>登录后才可查看购物车</div>',
          dangerouslyUseHTMLString: true,
          confirmText:"前往登录",
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }else{
        router.push({name: "ShopCar"});
      }
    }

    const name = ref(route.name);
    onMounted(() => {
      const guid = localStorage.getItem('guid')
      const data = qs.stringify({
          cartGUID: guid
      })
      axios.post('/M/Server/GetMyCartInfo', data)
        .then((res) => {
            if (res.data.success) {
              shopCarNum.value = res.data.id
            } else {
                Toast({
                    type: "error",
                    title: res.data.msg,
                });
            }
        })
        .catch((err) => {
            console.log('err');
        });
    })

    watch(
      () => route.name,
      () => {
        name.value = route.name;
      }
    );
    return {
      toViewCart,
      name,
      shopCarNum
    };
  },
});
